import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import About from "../components/About/About";
import config from "../../data/SiteConfig";
import Navbar from "../components/Navbar/Navbar";

class NotFound extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={`404 | ${config.siteTitle}`} />
        <Navbar/>
      </Layout>
    );
  }
}

export default NotFound;
